/* Remove scrollbar */
/* ::-webkit-scrollbar {
  display: none;
} */

/* Change scrollbar width */
::-webkit-scrollbar {
  width: 3px;
}

/* Change scrollbar color */
::-webkit-scrollbar {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #4fa3a5;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #397677;
}

.imagePost,
.videoPost {
  border-radius: 5px;
}

.videoPost {
  height: 500px;
}
